/*@media (min-width: 600px) {*/
/*    .general_table .MuiToolbar-regular {*/
/*       min-height: 0px !important;*/
/*    }*/
/*}*/

/*!*.general_table .MuiTableSortLabel-root.MuiTableSortLabel-active {*!*/
/*!*    color: white;*!*/
/*!*}*!*/

/*!*.general_table .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {*!*/
/*!*    color: white;*!*/
/*!*}*!*/
/*!*.general_table .MuiTableSortLabel-root:hover {*!*/
/*!*    color: white;*!*/
/*!*}*!*/

/*.general_table {*/
/*    max-width: 1200px;*/
/*}*/

/*.general_table .MuiTableRow-root.MuiTableRow-head:first-child th:first-child {*/
/*    text-align: center;*/
/*    border-top-left-radius: 4px;*/
/*}*/
/*.general_table .MuiTableRow-root.MuiTableRow-head:first-child th:last-child {*/
/*    text-align: center;*/
/*    border-top-right-radius: 4px;*/

/*}*/

/*.active_status {*/
/*    background-color: #e9f3e9;*/
/*    padding: 8px 12px;*/
/*    border-radius: 6px;*/
/*}*/
/*.inactive_status {*/
/*    background-color: #ffe9e9;*/
/*    padding: 8px 12px;*/
/*    border-radius: 6px;*/
/*}*/

/*.organization_row {*/
/*    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.14);*/
/*    padding: 8px 12px;*/
/*    margin-bottom: 4px;*/
/*    margin-top: 4px;*/
/*    border-radius:6px*/
/*}*/