.table td {
    padding: 5px;
    border: 1px solid #ddd;
}

.table table {
    position: relative;
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin: auto;
}

.MuiExpansionPanelDetails-root {
    padding: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-right: auto;
    margin-top: -8px;

}

.table tbody tr td:first-of-type {
    background-color: #eee;
    position: sticky;
    left: -1px;
    text-align: left;
}
