.container2 {
    padding: 10px;
    border: 1px solid black;
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
    padding-right: 2%;
    display: block;
    height: 200px;
    margin-top: 1%;
    border-radius: 10px;
}

.headline {
    /* position: absolute; */
    font-size: 1.2em;
    padding: 0.8em;
    display: block;
    /* background-color: rgb(186, 77, 77); */
    text-align: left;
    /* margin-top: 50px; */
    /* padding-top: 100px; */
}

.addpatients {
    /* display: flex; */
    margin-top: 48px;
    width: 100%;
}

.container2 input:focus {
    outline: none;
    border: none;
}

.inputs {
    position: absolute;
}

.MuiTooltip-tooltip {
    font-size: 15px !important;
}