.buttons-nav {
    display: flex;
    /*margin-top: 20px;*/
    /*margin-left: 1470px;*/
}

.button-nav {
    color: black;
    padding: 12px;
    text-align: right;
}

.header a {
    color: #fff;
    text-decoration: none;
}

.header a:hover {
    color: #F2F2F2;
}

.MuiList-padding {
    padding: 0;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    padding-top: 0px;
}

.transparent_paper .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
}

.MuiButton-startIcon.MuiButton-iconSizeMedium {
    margin-right: 0px;
}
