@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.App {
  text-align: center;
  /*font-family:'Roboto', sans-serif;*/
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  background-color: #F9FAFC;
}

.flag-dropdown {
  display: none;
}
